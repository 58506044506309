import { Calculation } from '@/types/mapping';
import { ConfigMap, FormatConfig } from '@/types/settings';
import { WBECombinedColumnConfig } from '@/types/writeback';
import { Column } from '@tableau/extensions-api-types';
import { ColDef, ColDefField } from 'ag-grid-community';

export function isCalculation(column: Column | Calculation | ColDef): column is Calculation {
  if (column) {
    return 'index' in column && typeof column.index === 'string' && column.index.startsWith('calc_');
  }
  return false;
}

export function isWBEColumn(
  column: Column | Calculation | ColDef | WBECombinedColumnConfig
): column is WBECombinedColumnConfig {
  if (column) {
    return 'origin' in column && column?.origin === 'writebackextreme';
  }
  return false;
}

export function getField(column: Column | Calculation | ColDef): any {
  // calculations get a unique calc_<timestamp> as index so we can just use that...
  // for fieldName we replace dots with underscores so ag-grid will not misinterpret them as
  // deep values, see: https://github.com/ag-grid/ag-grid/blob/master/packages/ag-grid-community/src/ts/utils.ts#L134
  // update: although no longer required, we have to keep doing this for now since column layout depends on the name
  if (isCalculation(column)) {
    return 'index' in column && column.index;
  } else if (isWBEColumn(column)) {
    return column.field;
  }

  if ('fieldName' in column) {
    return column.fieldName.replace(/\./g, '_');
  } else if ('field' in column && column.field) {
    let field: ColDefField = column.field;
    return field.replace(/\./g, '_');
  }
}

/* Column Groups Functions */
export function getColumnGroups(columnConfig: Object, columnGroupConfig: Object): string[] {
  const columnConfigList = Object.entries(columnConfig).map(([key, value]) => ({ key, ...value }));

  const groupHeaders: string[] = [];
  columnConfigList.forEach((column) => {
    if ('groupHeader' in column && column['groupHeader'] !== null) {
      groupHeaders.push(column['groupHeader']);
    }
  });
  // Get groups that dont have columns from the group config
  Object.keys(columnGroupConfig).forEach((columnConfigName) => {
    groupHeaders.push(columnConfigName);
  });
  return [...new Set(groupHeaders)];
}

export function getColumnsInGroup(columns: Array<Column>, columnsConfig: ConfigMap, groupName: string) {
  return columns.filter((column) => {
    return isCalculation(column)
      ? columnsConfig[column?.index]?.groupHeader === groupName
      : columnsConfig.hasOwnProperty(column?.fieldName) && columnsConfig[column?.fieldName]?.groupHeader === groupName;
  });
}

export function removeColumnFromGroup(columnField: String | number, dispatch: (action: any) => void) {
  dispatch({
    type: 'updateColumnConfig',
    index: columnField,
    change: { groupHeader: null },
  });
}

const BETWEEN_PARENTHESES = /\((.*)\)$/;
export function stripParentheses(column: Column | Calculation | WBECombinedColumnConfig) {
  if (isMeasure(column)) {
    let matches = column.fieldName!.match(BETWEEN_PARENTHESES);
    if (matches) {
      return matches[1];
    }
  }
  return column.fieldName;
}
/**
 * @todo: this should use field role
 * There are six pre-defined cell data types: 'text', 'number', 'boolean', 'date', 'dateString' and 'object'.
 * https://www.ag-grid.com/javascript-data-grid/cell-data-types/
 *
 * I have added 'number' here to make WBE number columns work
 */
export function isMeasure(column: Column | Calculation | ColDef | WBECombinedColumnConfig): boolean {
  const dataType = 'dataType' in column ? column.dataType : false;

  switch (dataType) {
    case 'int':
    case 'float':
    case 'number':
      return true;
    default:
      return false;
  }
}

export function cleanColumns(allColumns) {
  let tempStorage = allColumns;
  for (let i = 0; i < allColumns.length; i++) {
    if ('field' in allColumns[i]) {
      for (let j = 0; j < allColumns.length; j++) {
        const column = allColumns[j];
        if (!('field' in column)) {
          const fieldName = column.fieldName;
          if (fieldName === allColumns[i].field) {
            tempStorage = tempStorage.filter((entry) => entry._fieldName !== fieldName);
          }
        }
      }
    }
  }
  return tempStorage;
}

export function refreshDataSources() {
  // A log for debugging purposes. In case a customer reports that it is not working or slow, we can check the console.
  console.log('Refreshing data sources...');
  const reloadButton = document.getElementById('reloadButton');
  const reloadIcon = document.getElementById('reloadIcon');

  // Disable the button and apply the disabled styles
  reloadButton!.disabled = true;
  reloadButton!.style.backgroundColor = '#e0e0e0';
  reloadButton!.style.color = '#a0a0a0';
  reloadButton!.style.borderColor = '#a0a0a0';
  reloadButton!.style.cursor = 'not-allowed';

  // Start the rotation by adding the 'rotate' class to the icon
  reloadIcon!.classList.add('rotate');

  const isVizExtension = !!window.tableau.extensions.worksheetContent;

  const refreshDataSources = async () => {
    if (isVizExtension) {
      const dataSources = await window.tableau.extensions.worksheetContent.worksheet.getDataSourcesAsync();
      // Use for...of loop to await each refreshAsync call
      for (const dataSource of dataSources) {
        await dataSource.refreshAsync();
      }
    } else {
      const worksheets = window.tableau.extensions.dashboardContent.dashboard.worksheets;
      for (const worksheet of worksheets) {
        const dataSources = await worksheet.getDataSourcesAsync();
        for (const dataSource of dataSources) {
          await dataSource.refreshAsync();
        }
      }
    }
  };

  // Call the refresh function and handle the button and icon after completion
  refreshDataSources()
    .then(() => {
      // A log for debugging purposes. In case a customer reports that it is not working or slow, we can check the console.
      console.log('Data sources refreshed');
    })
    .catch((error) => {
      console.error('Error refreshing data sources:', error);
    })
    .finally(() => {
      stopRotationEnableButton(reloadButton, reloadIcon);
    });
}

function stopRotationEnableButton(reloadButton, reloadIcon) {
  // Stop the rotation and enable the button again
  reloadIcon.classList.remove('rotate');
  reloadButton.style.display = 'none';
  reloadButton.disabled = false;
  reloadButton.style.backgroundColor = '';
  reloadButton.style.color = '';
  reloadButton.style.borderColor = '';
  reloadButton.style.cursor = '';
}

export function parseDateTime(dateTimeString: string | undefined | null): Date {
  if (!dateTimeString) {
    // Return an invalid date if the string is undefined or null
    return new Date(NaN); // Invalid date to be handled by isValidDate
  }
  // Safely replace space with 'T' to make the format ISO-compliant
  if (dateTimeString && typeof dateTimeString === 'string') {
    return new Date(dateTimeString.replace(' ', 'T'));
  }
  return new Date(dateTimeString);
}

// Utility function to check if a date is valid
export function isValidDate(d: Date): boolean {
  return d instanceof Date && !isNaN(d.getTime());
}

export function isDate(column: Column | Calculation | WBECombinedColumnConfig): boolean {
  //added datetime to the list of date types and not sure why there is date-time
  switch (column.dataType) {
    case 'date':
    case 'datetime':
    case 'date-time':
      return true;
    default:
      return false;
  }
}

export function dateComparator(a: any, b: any) {
  let dateValueA = parseDateTime(a);
  let dateValueB = parseDateTime(b);

  const isValidA = isValidDate(dateValueA);
  const isValidB = isValidDate(dateValueB);

  if (!isValidA && !isValidB) {
    return 0;
  }
  if (!isValidA) {
    return 1;
  }
  if (!isValidB) {
    return -1;
  }

  // Both are valid, compare normally
  return dateValueA - dateValueB;
}

// replaces spaces, periods, and commas in a given string with underscores
export function removeSpaces(string: string) {
  return string.replace(/[ .,]/g, (match) => (match === ' ' ? '_' : ''));
}

export function isValueEmpty(value: undefined | null | string | number) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && !value.trim()) ||
    value === '%null%' ||
    (typeof value === 'number' && (Number.isNaN(value) || value === Infinity || value === -Infinity))
  );
}
/**
 * getFractionDigits gets the desired number of fraction digits from the config.
 * For floats the default is 2 fraction digits.
 * Other values are always displayed without fraction digits.
 */
export function getFractionDigits(column: Column | Calculation, config: FormatConfig): number {
  // :maximumFractionDigitsDefaultValue
  if (column.dataType === 'float') {
    let fractionDigits = config.maximumFractionDigits;
    if (fractionDigits === undefined || fractionDigits === '') {
      return 2;
    }
    return parseInt(fractionDigits);
  }
  return 0;
}
